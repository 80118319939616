@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  background-color: #fff;
  color: #fff;
  font-family: 'Geist-Medium' !important;
}

.text-black {
  color: #000;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

label {
  color: #000;
}

.custom-datepicker {
  width: 400px;
  height: 40px;
  border: 1.5px solid #044f66;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  color: #333;
  /* Add any other styles you want */
}

.rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label {
  display: block;
  overflow: hidden;
  text-overflow: inherit;
  font-size: 12px;
  white-space: normal !important;
  height: auto;
}


.nav {
  position: fixed;
  width: 300px;
  height: 100%;
  padding-right: 0px;
  background-color: #044f66;
  color: #fff !important;
}

.dash-main {
  margin-left: 330px;
  margin-right: 10px;
}

ul.navs {
  color: #fff;
  height: 100%;
  margin-left: -25px;
  margin-top: 10px;
  padding-bottom: 15px;
  padding-top: 10px;
  overflow: scroll;
}

ul.navs li {
  font-size: 20px;
  display: block;
  padding-bottom: 10px;
  margin-left: 10px;
  padding: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-top: 10px;
  padding-bottom: 15px;
  color: #fff;
}

.top-nav-datetime-component {
  width: 129%;
}

/* Media query for mobile devices */
@media (max-width: 968px) {
  .nav {
    width: 0; /* Hide the navigation bar */
  }

  .hidden-mobile {
    display: none;
  }

  .dash-main {
    margin-left: 10px; /* Adjust main content to use full width */
  }

  /* Show a button to open the navigation bar */
  #openNav {
    display: block;
  }

  .top-nav-datetime-component {
    width: 70%;
  }

  ul.navs {
    display: none; /* Hide the navigation items */
  }

  .App-dash {
    padding-top: 10px !important;
  }
}

@media (max-width: 968px) {
  /* ... existing styles */

  /* Show navigation when 'nav-open' class is applied */
  .nav {
    display: none;
  }

  .nav-open {
    display: block;
    /* Additional styles for showing the navigation */
    width: 300px;
    z-index: 1000;
    margin-top: 0px;
  }

  ul.navs {
    display: block; /* Hide the navigation items */
  }

  .messenger-search-card {
    display: none;
  }

  .messenger-chat-info {
    display: none;
  }

  .messenger-chats-card {
    width: 100% !important;
  }

}

/* Dim overlay styles */
.dim-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 999; /* Place the overlay above other content */
  pointer-events: auto; /* Allow clicking on the overlay */
}


@media (min-width: 568px) {
  .top-nav-datetime-component {
    width: 55%;
  }
}

@media (min-width: 1068px) {
  .hamburger-button {
    display: none; /* Hide the navigation bar */
  }

  .hidden-desktop {
    display: none;
  }
}

@media (min-width: 968px) {
  .hidden-desktops {
    display: none;
  }
}

/* Default styles for larger screens */
/* Add your existing styles here */

/* Media query for smaller screens */
@media (max-width: 1068px) {
  .hamburger-button {
    display: block;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    padding-bottom: 10px;
  }

  .hamburger-icon {
    display: inline-block;
    width: 20px;
    height: 2px;
    background-color: #000;
    position: relative;
    transition: background-color 0.3s ease;
  }

  .hamburger-icon:before,
  .hamburger-icon:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 2px;
    background-color: #000;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  .hamburger-icon:before {
    top: -6px;
  }

  .hamburger-icon:after {
    top: 6px;
  }

  /* Styles to show/hide navigation items */
  .nav-hidden {
    display: none;
  }
}

/* Base styles */
.header-container {
  background: #0a88ae;
  padding: 20px;
  padding-bottom: 20px;
  border-radius: 0;
  margin-bottom: -10px;
  margin-top: -10px;
  margin-left: -10px;
  margin-right: -10px;
}


.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-button {
  margin-top: -10px;
}

.header-button button {
  background-color: #044f66;
  color: white;
  padding: 5px 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.header-logo img {
  width: 200px;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .header-content {
    flex-direction: initial;
    align-items: center;
  }

  .header-button {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

/* Base styles */
.user-info-container {
  width: 45%;
}

.user-info-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.user-icons {
  display: flex;
  align-items: center;
}

.user-icons > * + * {
  margin-left: 10px;
}

.user-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 20px;
}

.user-greeting {
  color: #044f66;
  font-size: 16px;
  font-weight: bold;
}

.user-avatar {
  margin-top: 5px;
}


/* Responsive styles */
@media screen and (max-width: 768px) {
  .user-info-container {
    width: 100%;
  }

  .content {
    padding: 20px;
  }

  .user-info-content {
    justify-content: center;
    flex-direction: initial;
    align-items: center;
  }

  .user-details {
    margin-left: 0;
    margin-top: 10px;
    text-align: center;
  }
}


.active {
  background-color: #0a88ae;
}

.nav-link {
  margin-left: 10px;
}

.nav-logo {
  margin: 0px auto 0px;
  padding: 30px;
  padding-top: 20px;
  margin-bottom: -30px;
  width: 300px
}

.App-dash {
  background-color: #fff;
  height: 100vh;
  padding-top: 50px;
  color: #000;
  padding-bottom: 100px;
}

.logo {
  width: 300px;
}

.App {
  height: 100vh;
  background-color: #044f66;
  padding-top: 200px;
}

.project-card-image {
  width: 260px !important;
  border: 2px solid #ddd;
  border-radius: 5px;
}

.project-right-card {
  width: auto !important;
}

.faint-teal {
  font-family: inherit;
  font-size: inherit;
  background-color: rgba(235, 244, 247, 1);
  padding: 10px;
}

.auth-page {
  background: #044f66;
}

.auth-box {
  background: #fff;
  padding: 20px;
  max-width: 400px;
  margin: 0px auto 0px;
  border-radius: 5px;
  font-family: 'Geist-Medium';
}

.btn-sec {
  background-color: #044f66;
}

.primary-color {
  color: #044f66;
}

.primary-color-bg {
  background-color: #044f66;
}

.padding {
  padding: 10px;
}

.text-white {
  color: #fff;
}
/* 
.ui.button {
  background-color: #044f66;
} */

.chat-container {
  display: flex;
  flex-direction: column;
  height: 370px;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.message {
  display: flex;
  margin-bottom: 10px;
}

.user-message {
  justify-content: flex-start;
  padding-top: 10px;
}

.rbc-time-content>.rbc-time-gutter {
  flex: none;
  display: none;
}

.bot-message {
  justify-content: flex-end;
}

.message-content {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.message-header {
  font-size: 12px;
}

.message-text-user {
  background: #044f66;
  border-radius: 10px;
  color: #fff !important;
  padding: 8px;
  word-wrap: break-word;
}

.message-text-bot {
  background: #e0e0e0;
  border-radius: 10px;
  padding: 8px;
  word-wrap: break-word;
}

.message-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
}

input {
  flex: 1;
  padding: 5px;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
}


.compact-datepicker input {
  display: none;
}

button {
  padding: 5px 10px;
  background: #044f66;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

ul {
  list-style: none;
}

.messenger-contact-card-list {
  padding-bottom: 20px;
  margin-left: -50px;
}

.timesheet-table {
  padding-bottom: 20px;
  margin-left: -19px;
}

.timesheet-table li {
  padding-bottom: 15px;
}

.timesheet-notice-text {
  padding-top: 10px;
  font-size: 15px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.grp-view-page-further-checks-card {
  padding: 10px;
  background-color: #f4f4f4;
  margin-right: 10px;
  border-radius: 5px;
}

.multiple-site {
  padding: 10px;
  background-color: #f4f4f4;
}

.filter-options {
  float: right;
}

.create-project-button {
  margin-left: 60px;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .timesheet-container {
    padding: 10px; /* Add padding for better mobile layout */
  }

  .create-project-button {
    margin-left: 0px;
  }

  .timesheet-subtitle {
    color: #ddd;
    font-size: 20px;
  }

  .filter-dates {
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }

  .filter-button {
    padding-top: 5px;
  }
}


/* Responsive styles */
@media screen and (max-width: 1068px) {
  .projects-container {
    padding: 10px; /* Add padding for better mobile layout */
  }

  .projects-subtitle {
    margin-top: 5px;
    font-size: 14px;
  }

  .create-project-button {
    padding-top: 10px;
    text-align: center;
  }

  .filter-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    float: none;
  }

  .filter-label {
    padding-bottom: 5px;
  }

  .filter-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .cards-container {
    padding: 10px; /* Add padding for better mobile layout */
  }

  .project-card {
    margin: 10px 0;
  }

  .card-link {
    text-decoration: none;
    color: #000;
    text-align: justify;
  }

  .order-number {
    padding-top: 10px;
    padding-bottom: 5px;
  }
}

/* Default styles for desktop */
.custom-card-group {
  display: flex;
  flex-wrap: wrap;
}

/* Adjust for tablet (2 items per row) */
@media screen and (max-width: 991px) {
  .custom-card-group {
    justify-content: space-between;
  }
}

/* Adjust for mobile (1 item per row) */
@media screen and (max-width: 767px) {
  .custom-card-group {
    justify-content: center;
  }
}

.sigCanvas {
  border: 1px solid #044f66;
  border-radius: 5px;
}

.grey {
  padding: 10px;
  background-color: #ececec;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.title {
  padding: 10px;
  background-color: yellow;
  text-align: center;
  font-weight: bold;
}

.labeltitle {
  padding: 10px;
}

.custom-table.ui.table {
  border: none;
}

.custom-table.ui.table thead tr th {
  border: none;
}

.custom-table.ui.table tbody tr td {
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-right: 1px solid rgba(34, 36, 38, 0.1);  /* Keep only column separators */
}

.custom-table.ui.table tbody tr td:last-child {
  border-right: none;  /* Remove border from last column */
}