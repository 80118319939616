
.messenger-input .ui.input > input {
    border-radius: 99px;
}

ui.input > input {
    border: 1px solid #000 !important;
}

.messenger-search-input {
    padding-top: 20px;
}

.messenger-contact-card-container {
    display: flex;
    padding-top: 10px;
}

.messenger-contact-card-list {
    list-style-type: none;
}

.messenger-contact-card-list-item {
    display: flex;
    padding: 16px;
    cursor: pointer;
    width: 600px;
}

.messenger-contact-card-list-item-active {
    background: #044f66;
    padding: 16px;
    color: white !important;
    width: 460px;
}

.messenger-contact-card-list-item-name-flex {
    display: flex;
}

.messenger-contact-card-list-item-time {
    float: right;
}

.messenger-contact-card-list-item-username {
    margin-top: 1px;
    margin-left: 6px;
    width: max-content;
}

.messenger-contact-card-list-item-summary-text {
    font-size: 10px;
    color: inherit;
}

.messenger-container {
    display: flex;
    margin-top: 50px;
    padding-bottom: 50px;
    margin-right: 5px;
    height: 600px;
    box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  @media (max-width: 968px) {
    .messenger-container {
      display: block;
    }
  }
  
  .messenger-search-card {
    padding: 10px;
    width: 239px;
    height: 600px;
      overflow: scroll;
    background-color: #f2f2f2;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }


.messenger-chats-card {
    background-color: #fff;
    height: 600px;
    padding: 10px;
    max-width: 1054px;
    width: 900px;
}
  
  .messenger-chat-info {
      border-left: 2px solid #f2f2f2;
      height: 600px;
      padding: 10px;
      width: 100%;
  }
  
  .messenger-search-input {
    border-radius: 99px;
    padding-right: 80px;
    margin-left: -5px;
  }

  .messenger-contact-card-list-item-time-text {
    margin-left: 10px;
    color: #ddd;
  }

  .messenger-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid #e0e0e0;
    background: #f2f2f2;
    border-radius: 10px;
  }

  .messenger-chats-divider {
    padding-left: 0px;
    margin-left: -10px !important;
    margin-right: -10px !important;
      margin-bottom: -1px;
  }

  .messenger-chats-name {
    padding-top: 10px;
    padding-left: 10px;
      padding-bottom: 8px
  }


.chat-container-messenger {
    display: flex;
    flex-direction: column;
    height: 520px;
}