.chat-layout {
    display: flex;
    height: calc(80vh - 100px);
    margin: 20px;
    background: #ffffff;
    border-radius: 16px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.chat-sidebar {
    width: 320px;
    background: #f8fafc;
    border-right: 1px solid #e2e8f0;
    display: flex;
    flex-direction: column;
}

.chat-search {
    padding: 16px;
    border-bottom: 1px solid #e2e8f0;
}

.chat-search-input {
    width: 100%;
    padding: 10px 16px;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
    background: #ffffff;
    transition: all 0.2s;
}

.chat-search-input:focus {
    border-color: #044f66;
    box-shadow: 0 0 0 2px rgba(4, 79, 102, 0.1);
}

.chat-contacts {
    flex: 1;
    overflow-y: auto;
}

.contact-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.contact-item {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    cursor: pointer;
    transition: all 0.2s;
    border-bottom: 1px solid #f1f5f9;
}

.contact-item:hover {
    background: #f1f5f9;
}

.contact-item.active {
    background: #044f66;
    color: white;
}

.contact-avatar {
    margin-right: 12px;
}

.contact-info {
    flex: 1;
}

.contact-name {
    font-weight: 600;
    margin-bottom: 4px;
}

.contact-status {
    font-size: 12px;
    color: #64748b;
}

.chat-main {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.chat-header {
    padding: 16px;
    border-bottom: 1px solid #e2e8f0;
    display: flex;
    align-items: center;
    background: #ffffff;
}

.chat-messages {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    background: #ffffff;
}

.message {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.message-incoming {
    align-items: flex-start;
}

.message-outgoing {
    align-items: flex-end;
}

.message-content {
    max-width: 70%;
    padding: 12px 16px;
    border-radius: 12px;
}

.message-incoming .message-content {
    background: #f1f5f9;
    border-top-left-radius: 4px;
}

.message-outgoing .message-content {
    background: #044f66;
    color: white;
    border-top-right-radius: 4px;
}

.message-time {
    font-size: 12px;
    margin-bottom: 4px;
    color: #64748b;
}

.chat-input-container {
    padding: 16px;
    background: #ffffff;
    border-top: 1px solid #e2e8f0;
}

.chat-input-wrapper {
    display: flex;
    gap: 12px;
    align-items: center;
}

.chat-input {
    flex: 1;
    padding: 12px 16px;
    border-radius: 24px;
    border: 1px solid #e2e8f0;
    background: #f8fafc;
    transition: all 0.2s;
}

.chat-input:focus {
    border-color: #044f66;
    background: #ffffff;
    box-shadow: 0 0 0 2px rgba(4, 79, 102, 0.1);
}

.chat-button {
    padding: 8px 16px;
    border-radius: 24px;
    border: none;
    background: #044f66;
    color: white;
    cursor: pointer;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    gap: 8px;
}

.chat-button:hover {
    background: #033e52;
}

.mobile-menu-button {
    display: none;
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    color: #044f66;
    margin-right: 8px;
}

.mobile-menu-button:hover {
    background: rgba(4, 79, 102, 0.1);
    border-radius: 4px;
}

@media (max-width: 768px) {
    .mobile-menu-button {
        display: block;
    }

    .chat-sidebar {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 1000;
        transform: translateX(-100%);
        transition: transform 0.3s ease;
    }

    .chat-sidebar.active {
        transform: translateX(0);
    }

    .chat-sidebar.active::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 999;
    }

    .chat-sidebar {
        z-index: 1000;
    }
}
@media (max-width: 768px) {
    .chat-sidebar.active::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0);
        z-index: 999;
    }

    .chat-sidebar {
        z-index: 1000;
    }
}